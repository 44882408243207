<template>
  <ul>
    <CartItem v-for="item in items" :key="item._id" :item="item" :order="order" />
  </ul>
</template>

<script>
import CartItem from "./CartItem";
export default {
  props: ["items", "order"],
  components: {
    CartItem
  }
};
</script>

<style scoped lang="scss">
ul {
  @include grid($cols: 100%, $row-gap: 15px);
  padding-right: 15px;
  padding-bottom: 15px;
}
</style>