<template>
  <li class="pos__rel">
    <img :src="activeProduct.images[0]" width="50px" height="50px" />
    <router-link
      :to="`/new-order/${order._id}/products/${item.product}?item=${item._id}`"
      class="flex__col link__none"
    >
      <span class="text__overflow text__bold text__blue">{{ activeProduct.name }}</span>
      <span class="flex__between text__sm">
        <span class="flex__left">
          <span>Qty:</span>
          <span class="ml-10">{{ item.quantity }}</span>
        </span>
        <span class="flex__right">
          <span class="ml-10">${{ (item.quantity * item.loCost).toFixed(2) }}</span>
        </span>
      </span>
    </router-link>
    <button class="btn__red" @click="toggleDeleting">
      <font-awesome-icon icon="times" size="lg" />
    </button>
    <div v-if="deleteToggled" class="dual--buttons">
      <div class="btn__green flex__center" @click="deleteOrderItem">Delete</div>
      <div class="btn__red flex__center" @click="toggleDeleting">Cancel</div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["item", "order"],
  data() {
    return {
      deleteToggled: false
    };
  },
  computed: {
    ...mapGetters(["getProducts"]),
    activeProduct() {
      return this.getProducts.filter(product => {
        return product._id === this.item.product;
      })[0];
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startDeleteOrderItem"]),
    async deleteOrderItem() {
      try {
        this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
        await this.startDeleteOrderItem(this.item);
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    toggleDeleting() {
      this.deleteToggled = !this.deleteToggled;
    }
  }
};
</script>

<style scoped lang="scss">
li {
  @include grid($cols: 50px calc(100% - 100px) 40px, $col-gap: 5px);
  background-color: #fff;
  border: 1px solid $black;
}
button {
  border: none;
}
.dual--buttons {
  @include pos-abs($pos: absolute, $height: 100%, $width: 100%);
  div {
    opacity: 0.8;
  }
}
</style> 